import { listSetting, listSettingQuery } from '@/api/cmsApi'
import TableColumnConfig from '@/vab/components/TableColumnConfig'

export default {
  components: {
    TableColumnConfig,
  },
  data() {
    return {
      updateStoreID: '',
      customConfig: {
        storage: true,
        restoreStore: async (tableInfo) => {
          const res = await listSettingQuery(tableInfo.id)
          this.updateStoreID = res.data.id
          return JSON.parse(res.data.userSetting)
        },
        updateStore: async (tableInfo) => {
          if (this.updateStoreID == '') {
            const res = await listSetting({
              userSetting: JSON.stringify(tableInfo.storeData),
              tableName: tableInfo.id,
            })
            this.updateStoreID = res.data
          } else {
            await listSetting({
              id: this.updateStoreID,
              userSetting: JSON.stringify(tableInfo.storeData),
              tableName: tableInfo.id,
            })
          }
        },
        mode: 'modal',
        modalOptions: {
          width: 830,
          height: 460,
        },
        slots: {
          default: (params, h) => {
            return h(TableColumnConfig, {
              props: params,
            })
          },
        },
      },
    }
  },
  methods: {
    openColumnSettings() {
      const $table = this.$refs.tableRef

      if ($table) {
        $table.openCustom()
      }
    },
  },
}
